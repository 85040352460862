<template>
  <b-row class="match-height d-flex align-items-center">
    <b-col
      v-if="!isDateHidden"
      cols="12"
      md="6"
      class="mb-1"
    >
      <b-input-group>
        <b-form-datepicker
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Stok Fatura Tarihi Başlangıç',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append v-if="filterData.sdate">
          <b-button
            variant="warning"
            @click="removeDate('sdate')"
          >
            <FeatherIcon icon="XCircleIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col
      v-if="!isDateHidden"
      cols="12"
      md="6"
      class="mb-1"
    >
      <b-input-group>
        <b-form-datepicker
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Stok Fatura Tarihi Bitiş',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append v-if="filterData.edate">
          <b-button
            variant="warning"
            @click="removeDate('edate')"
          >
            <FeatherIcon icon="XCircleIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col>
      <v-select
        v-model="filterData.id_com_brand"
        :options="brands"
        label="title"
        :reduce="brand => brand.id"
        placeholder="Marka"
        @input="getModels"
      />
    </b-col>
    <b-col>
      <v-select
        v-model="filterData.id_com_model"
        :options="models"
        label="title"
        :reduce="model => model.id"
        placeholder="Model"
        :disabled="!filterData.id_com_brand"
      />
    </b-col>
    <b-col>
      <b-form-input
        v-model="filterData.chasis"
        placeholder="Şase"
      />
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        :disabled="loadingData"
        @click="getFilteredData"
      >
        <FeatherIcon icon="FilterIcon" />
        Filtrele
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BButton, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'StockFinancialCostFilter',
  components: {
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormInput,
  },
  props: {
    getFilteredData: {
      type: Function,
      required: true,
    },
    isDateHidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    filterData() {
      return this.$store.getters['salesManagerGpr/getStockFinancialFilterParams']
    },
    loadingData() {
      return this.$store.getters['salesManagerGpr/getStockFinancialLoading']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    removeDate(type) {
      if (type === 'sdate') {
        this.filterData.sdate = null
      }
      if (type === 'edate') {
        this.filterData.edate = null
      }
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getModels() {
      this.filterData.id_com_model = null
      this.$store.dispatch('models/modelsList', {
        select: [
          'com_model.id AS id',
          'com_model.title AS title',
        ],
        where: {
          'com_model.id_com_brand': this.filterData.id_com_brand,
        },
      })
    },
  },
}
</script>
